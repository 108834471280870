import * as React from 'react'

import { Col, Row } from 'react-bootstrap'
import { graphql, StaticQuery } from 'gatsby'
import Content from '../components/Content'
import Layout from '../layouts/layout'
import ContactForm from '../components/contact/ContactForm'
import AddressMap from '../components/AddressMap'
import { getPhoneByFormat } from '../styles/mixins'

type ContactInfoQuery = {
  site: {
    siteMetadata: {
      company: {
        workHours: string[]
        address: {
          zip: string
          city: string
          street: string
          house: number
        }
        contacts: {
          phone: number
          viber: number
          email: string
        }
      }
    }
  }
}

const Contact = () => (
  <StaticQuery
    query={graphql`
      query ContactInfoQuery {
        site {
          siteMetadata {
            company {
              workHours
              address {
                zip
                city
                street
                house
              }
              contacts {
                phone
                viber
                email
              }
            }
          }
        }
      }
    `}
    render={(data: ContactInfoQuery) => (
      <Layout>
        <Content>
          <Row>
            <h1>Контактная информация</h1>
            <Col md={8}>
              <p>
                {data.site.siteMetadata.company.address.zip}, г. {data.site.siteMetadata.company.address.city}, ул.{' '}
                {data.site.siteMetadata.company.address.street}, д. {data.site.siteMetadata.company.address.house}
              </p>
              <div>
                {data.site.siteMetadata.company.workHours.map(wh => (
                  <p>{wh}</p>
                ))}
              </div>
              <p>
                Телефон{' '}
                <a href={`tel:${getPhoneByFormat(data.site.siteMetadata.company.contacts.phone.toString(), '+7xxxxxxxxxx')}`}>
                  {getPhoneByFormat(data.site.siteMetadata.company.contacts.phone.toString(), '+7 (xxx) xxx-xx-xx')}
                </a>
              </p>
              <p>Viber {getPhoneByFormat(data.site.siteMetadata.company.contacts.viber.toString(), '+7 (xxx) xxx-xx-xx')}</p>
              <p>{data.site.siteMetadata.company.contacts.email}</p>
            </Col>
            <Col md={4} className="mb-4">
              <ContactForm />
            </Col>
          </Row>
        </Content>
        <AddressMap width="100%" height="450px" frameBorder="0" />
      </Layout>
    )}
  />
)

export default Contact

import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { colors } from '../../styles/variables'

class ContactForm extends React.PureComponent {
  render() {
    return (
      <Form method="POST" action="/contact-form.php">
        <Form.Group>
          <Form.Label>Имя</Form.Label>
          <Form.Control name="clientName" placeholder="Борис" />
        </Form.Group>

        <Form.Group>
          <Form.Label>Телефон</Form.Label>
          <Form.Control name="clientPhone" type="number" placeholder="+7 (999) 123-45-67" required />
        </Form.Group>

        <Form.Group>
          <Form.Label>Эл. почта</Form.Label>
          <Form.Control name="clientEmail" type="email" placeholder="boris.bv@example.com" />
        </Form.Group>

        <Form.Group>
          <Form.Label>Текст</Form.Label>
          <Form.Control name="clientText" as="textarea" rows={3} />
        </Form.Group>

        <Form.Group>
          <Form.File name="clientData" label="Приложить файлы" />
        </Form.Group>
        <Button type='submit' style={{ background: colors.warning, border: 'none' }}>Отправить</Button>
      </Form>
    )
  }
}

export default ContactForm
